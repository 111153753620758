import { GraphQLError } from 'graphql'

// This type is compatible with both ErrorResponse and ApolloError
interface GenericError {
  graphQLErrors?: ReadonlyArray<GraphQLError>
}

// TODO: KAvi come back to this later
export const containsErrorCode: (error: GenericError, code: string) => boolean = (error, code) => {
  const { graphQLErrors } = error
  if (!graphQLErrors) {
    return false
  }
  return graphQLErrors.find(({ extensions }) => extensions && extensions.code === code) != null
}
