
import { ApolloError } from 'apollo-client'
import { ErrorCode } from '~/types'
import { containsErrorCode } from './graphql'

export const getMemberInviteError =  (error: ApolloError): string => {
  let errorMessage = 'Sorry, something went wrong while sending the invitation.'
  if (containsErrorCode(error, ErrorCode.MEMBER_ALREADY_EXISTS))
    errorMessage = 'Sorry, it looks like a member already exists with the provided email.'
  if (containsErrorCode(error, ErrorCode.VALIDATION_ERROR)) errorMessage = error.message

  return errorMessage
}
